@import 'components/mixins.scss';

.base {
  //background: rgb(2,0,36);
  background: $green;
  color: #fff;
  // background: linear-gradient(
  //   83deg,
  //   rgba(0, 0, 0, 1) 0%,
  //   rgba(0, 0, 0, 1) 71%,
  //   rgba(0, 0, 0, 1) 100%
  // );
  overflow: hidden;

  // background: linear-gradient(90deg, rgba(51, 51, 51, 1) 0%, rgba(41, 45, 69, 1) 35%, rgba(57, 64, 103, 1) 100%);
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  padding: 0;

  &.white {
    // background: $white;
    background: #000;
  }

  &.gray {
    background: $gray-1;
  }
}

.bottombar {
  position: absolute;
  bottom: 0;
  height: rem(100);
  display: flex;
  // background-color: transparent;
  background-color: #000;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: rem(28);
  width: 100%;
  @media (max-width: $md-max-width) {
    //align:center;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
  .bottomText {
    font-size: rem(16);
    font-weight: 200;
    color: #fff;
    @media (max-width: $md-max-width) {
      font-size: rem(12);
    }
  }
}
