@import 'components/mixins.scss';

@font-face {
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Gmarket Sans Bold'), local('GmarketSans-Bold'),
    url('/resources/fonts/gmarket/GmarketSansBold.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/resources/fonts/gmarket/GmarketSansBold.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Gmarket Sans Medium'), local('GmarketSans-Medium'),
    url('/resources/fonts/gmarket/GmarketSansMedium.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/resources/fonts/gmarket/GmarketSansMedium.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Gmarket Sans Light'), local('GmarketSans-Light'),
    url('/resources/fonts/gmarket/GmarketSansLight.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/resources/fonts/gmarket/GmarketSansLight.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'S-Core';
  font-weight: 100;

  src: url('/resources/fonts/S-CoreDream/S-CoreDream-1Thin.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 200;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-2ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 300;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-3Light.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 400;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-4Regular.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 500;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-5Medium.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 600;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-6Bold.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 700;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-7ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 800;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-8Heavy.woff') format('woff');
}
@font-face {
  font-family: 'S-Core';
  font-weight: 900;
  src: url('/resources/fonts/S-CoreDream/S-CoreDream-9Black.woff') format('woff');
}
