// Colors
$white: #fefcfd;
$black: #00152a;
$blue: #005eb8;
$blue-light: #0091da;
$blue-dark: #00338d;
$gray-1: #f8f7f9;
$gray-1-5: #f3f2f4;
$gray-2: #e4e9f0;
$gray-3: #d9dee9;
$gray-4: #868984;
$gray-5: #4a5048;
$gray-6: #10171f;
// $gray-6: #2c332a;
$yellow: #f5ef43;
$orange: #080807;
$red: #ef2d56;
$red-dark: #b92142;
$pink: #ee8a89;
$violet: #cb77a6;
$purple: #925e78;
$purple-light: #6d2077;
$purple-dark: #483698;
$green: #00a3a1;
$dark-green: #086788;
$light-green: #8cd8d4;

$main-gradient: linear-gradient(233deg, #ee8a89, #ef2d56);
$blue-gradient: linear-gradient(233deg, #ff1f6f, #ff8360);

$body-background: $white;

$topbar-background: $white;
$topbar-text: $black;
$handler-color: $black;

$footer-background: $gray-6;
$footer-color: $gray-2;
$footer-border: $gray-6;

$text: $black;
$border: $gray-2;

$menu-is-transparent: false;
$menu-align-right: true;
// $menu-background-color: rgba(0, 0, 0, 0.7);
$menu-background-color: $white;
$menu-color: $white;
$menu: $black;
$menu-hover: $purple;
$menu-selected: $purple;

$menu-left-background: $white;
$menu-left-color: $blue-dark;
$menu-left-item-color-hover: $white;
$menu-left-item-background-hover: $gray-1;

$menu-left-submenu-title: $black;
$menu-left-submenu-title-hover: $blue;
$menu-left-submenu-group-background: $gray-1;
$menu-left-submenu-opened-color: $blue;
$menu-left-selected-color: $white;
$menu-left-selected-background: $blue-light;

$global-background-enabled: false;
$global-background-image: url('/resources/images/background.png');

$global-backgrounc-repeat: no-repeat;
$global-background-color: $black;
$global-background-size: cover;

$program-border: $gray-2;
$program-body-background: $white;
$program-body-background-odd: $gray-1-5;
$program-header-background: $blue;
$program-header-color: $white;
$program-break-background: $gray-3;
$program-openingclosing-background: $gray-2;
$program-current-background: $blue-light;
$program-current-color: $black;

// Accent colors
$default: $gray-4;
$primary: $purple;
$secondary: $blue-dark;
$success: $blue-light;
$info: $blue;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;

// Font Family
// $base-font-family: 'Noto Sans KR', sans-serif;
// $base-font-family: 'Poppins', sans-serif;
// $base-font-family: 'Gothic A1', sans-serif;
// $base-font-family: 'Gmarket Sans', sans-serif;
// $base-font-family: 'S-Core', sans-serif;
// $base-font-family: 'Mukta', sans-serif;
// $base-font-family: 'NanumSquare', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
// 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
// sans-serif;
// $base-font-family: 'S-Core', sans-serif;
$base-font-family: 'Noto Sans KR', sans-serif;
$input-font-family: 'Noto Sans KR', sans-serif;
$input-text-color: $black;

// Font Size
$base-font-size: 15 !default;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{calc(floor(calc($px/$base) * 100) / 100)}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
